#specifics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 80%;
    background-color: white;
    padding: 20px 40px;
    border-radius: 10px;
    margin-bottom: 50px;
}
#specifics-container>h3 {
    width: 100%;
}

#specifics-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    gap: 30px;
}

.specifics-element-content>p {
    margin: 5px 0;
}

.specifics-element>h5 {
    margin-bottom: 10px;
}

.specifics-element {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 563px){
    .specifics-element>h5 {
        width: 100%;
        text-align: center;
    }
    .specifics-element-content {
        text-align: center;
    }
}
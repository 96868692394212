#header-container {
    width: 90%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}


/********** Header logo (left) **********/
#header--logo {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

#header--logo-content>img {
    width: 60px;
    height: 60px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
}

#header--logo-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#header--logo-content-title{
    display: flex;
    flex-direction: column;
}
#header--logo-content-title>h2 {
    font-family: 'Snell Roundhand', serif;
    font-style: italic;
    font-weight: 500;
    font-size: 34px;
    line-height: 25px;
}
#header--logo-content-title>h3 {
    font-family: 'Snell Roundhand', serif;
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    width: 100%;
    text-align: center;
}

#header--logo-content-location {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: unset;
    color: black;
}
#header--logo-content-location>img {
    margin-right: 10px;
}


/********** Header infos (center) **********/
#header-info {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
}

#header-info>p {
    font-size: 15px;
    display: flex;
}

.header-info-separator{
    margin: 0 10px;
}

@media screen and (max-width: 800px) {
    #header-info {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 80px;
    }
}

@media screen and (max-width: 400px) {
    #header-info {
        display: none;
    }
}

#footer-container {
    width: 100%;

    margin-top: 10vh;
    padding: 2% 0;

    position: relative;
}

#footer-container > p {
    text-align: center;
}


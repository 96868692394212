#map-container{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

#map-content {
    width: 70%;
}

h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 80%;
}

@media screen and (max-width: 1000px){
    #map-content {
        width: 90%;
    }
    h3{
        text-align: center;
    }
}
@media screen and (max-width: 600px){
    #map-content {
        width: 100%;
    }
}
#App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.separator {
  width: 80%;
  height: 1px;
  background-color: #9F9F9F;
  margin: 80px 0;
}
#switch-language--container {
    position: relative;
    z-index: 1;
}

.switch-language--flag {
    width: 20px;
    height: 20px;

    border-radius: 50%;
}



/*-------------------------------- Button ---------------------------------*/
#switch-language--button {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    height: 24px;
    width: 100%;

    border-top: unset;
    border-left: unset;
    border-right: unset;
    background: unset;

    border-bottom: 1px solid #C6C6C8;
}

#switch-language--button > p {
    margin-left: 10px;
}

#switch-language--button-next {
    position: relative;
    right: 0;

    margin-left: 10px;
    width: 7px;

    transform: rotate(90deg);
}


/*-------------------------------- Options ---------------------------------*/
#switch-language--options {
    position: absolute;

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
}

.switch-language--options-item {
    cursor: pointer;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;

    width: 100%;
    height: 24px;

    margin-top: 10px;
}

.switch-language--options-item > p {
    margin-left: 10px;
}

@media screen and (max-width: 450px) {
    #switch-language--button > p {
        display: none;
    }

    .switch-language--options-item > p {
        display: none;
    }
}


@media screen and (max-width: 350px) {
    #switch-language--container {
        display: none;
    }
}
#infos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 80%;
    background-color: white;
    padding: 20px 40px;
    border-radius: 10px;
    margin-bottom: 50px;
}
#infos-container>h3 {
    width: 100%;
}

#infos-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    gap: 30px;
}

.infos-element-content>p {
    margin: 5px 0;
}

.infos-element>h5 {
    margin-bottom: 10px;
}
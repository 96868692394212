#presentation-container {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

#presentation-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}


#presentation-contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}


#contact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

#contact-content>img {
    width: 115px;
    border-radius: 50%;
}


#contact-content-tel {
    background-color: #3194AA;
    padding: 10px 15px;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: row;
    text-decoration: unset;
    gap: 10px;
}


#contact-content-infores {
    font-size: 10px;
    color: #6B7280;
}

#contact-popup {
    position: fixed;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 1;

    background: #3194AA;
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.5);
    border-radius: 50%;

    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
}

#contact-popup:hover {
    background-color: #3BAA31;
}

#presentation-contact > p {
    font-size: 16px;
}


@media screen and (max-width: 1100px) {
    #presentation-contact {
        position: fixed;
        right: 0;
    }
    #presentation-contact > p {
        display: none;
    }

    #contact-popup {
        display: flex;
    }
}







#presentation-description {
    width: 90vw;
    padding: 10px 20px;
    background-color: white;
    border-radius: 10px;
}

#presentation-description {
    text-align: justify;
}

#presentation-description>p {
    font-size: 14px;
}